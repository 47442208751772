#components-layout-demo-top-side-2 .logo {
	width: 120px;
	height: 31px;
	background: red;
	margin: 16px 28px 16px 0;
	float: left;
}

.main-header {
    text-align: right;
}

.user-panel {
    float: right;
    min-width: 200px;
    background: inherit !important;
}

.app-logo {
    height: 50px;
    border-radius: 96px;
}

.home-icon {
    background: inherit !important;
    cursor: default;
}

.ant-layout-header {
    height: 64px;
    padding: 0 0px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #001529;
}

